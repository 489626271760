import './App.css';

function App() {
  return (
    <div className="container">
      <h1>Site Under Construction</h1>
      <p>This site is currently under construction. Please come back later.</p>
      <a href="https://www.linkedin.com/in/yann-tomkowiak-561b7a208/" target="_blank" rel="noopener noreferrer" className="linkedin-button">
        My LinkedIn
      </a>
    </div>
  );
}

export default App;
